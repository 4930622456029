var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "validation-forms", fluid: "", tag: "section" } },
    [
      _c("base-v-component", {
        attrs: { heading: "Forms", link: "components/forms" }
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "py-3 px-5",
                  attrs: {
                    color: "success",
                    icon: "mdi-mail",
                    title: "Register Form"
                  }
                },
                [
                  _c(
                    "v-form",
                    [
                      _c("v-text-field", {
                        attrs: { color: "secondary", label: "Email Address*" }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Password*",
                          "append-icon": _vm.show ? "mdi-eye" : "mdi-eye-off",
                          type: _vm.show ? "text" : "password",
                          color: "secondary"
                        },
                        on: {
                          "click:append": function($event) {
                            $event.preventDefault()
                            _vm.show = !_vm.show
                          }
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Confirm Password*",
                          "append-icon": _vm.show2 ? "mdi-eye" : "mdi-eye-off",
                          type: _vm.show2 ? "text" : "password",
                          color: "secondary"
                        },
                        on: {
                          "click:append": function($event) {
                            $event.preventDefault()
                            _vm.show2 = !_vm.show2
                          }
                        }
                      }),
                      _c("div", { staticClass: "body-2 font-weight-light" }, [
                        _vm._v("\n            * Required Fields\n          ")
                      ]),
                      _c(
                        "v-card-actions",
                        { staticClass: "pa-0" },
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Subscribe to newsletter" }
                          }),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            { attrs: { default: "", color: "success" } },
                            [_vm._v("\n              Register\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "py-3 px-5",
                  attrs: {
                    color: "success",
                    icon: "mdi-camera-account",
                    title: "Login Form"
                  }
                },
                [
                  _c(
                    "v-form",
                    [
                      _c("v-text-field", {
                        attrs: { color: "secondary", label: "Email Address*" }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Password*",
                          "append-icon": _vm.show3 ? "mdi-eye" : "mdi-eye-off",
                          type: _vm.show3 ? "text" : "password",
                          color: "secondary"
                        },
                        on: {
                          "click:append": function($event) {
                            $event.preventDefault()
                            _vm.show3 = !_vm.show3
                          }
                        }
                      }),
                      _c(
                        "v-card-actions",
                        { staticClass: "pa-0" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            { attrs: { default: "", color: "success" } },
                            [_vm._v("\n              Login\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "form",
              {
                attrs: { action: "", "data-vv-scope": "form-1" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.validateForm("form-1")
                  }
                }
              },
              [
                _c(
                  "base-material-card",
                  {
                    staticClass: "px-5 py-3",
                    attrs: {
                      color: "success",
                      inline: "",
                      title: "Type Validation"
                    }
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center", dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right body-1 grey--text",
                            attrs: { cols: "2" }
                          },
                          [
                            _vm._v(
                              "\n              Required Text\n            "
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              attrs: {
                                "error-messages": _vm.errors.collect(
                                  "form-1.required"
                                ),
                                color: "secondary",
                                "data-vv-name": "required"
                              },
                              model: {
                                value: _vm.required,
                                callback: function($$v) {
                                  _vm.required = $$v
                                },
                                expression: "required"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-col", [_c("code", [_vm._v("required")])])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { attrs: { align: "center", dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right body-1 grey--text",
                            attrs: { cols: "2" }
                          },
                          [_vm._v("\n              Email\n            ")]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|email",
                                  expression: "'required|email'"
                                }
                              ],
                              attrs: {
                                "error-messages": _vm.errors.collect(
                                  "form-1.email"
                                ),
                                color: "secondary",
                                "data-vv-name": "email",
                                type: "email"
                              },
                              model: {
                                value: _vm.email,
                                callback: function($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-col", [_c("code", [_vm._v('type="email"')])])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { attrs: { align: "center", dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right body-1 grey--text",
                            attrs: { cols: "2" }
                          },
                          [_vm._v("\n              Number\n            ")]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|numeric",
                                  expression: "'required|numeric'"
                                }
                              ],
                              attrs: {
                                "error-messages": _vm.errors.collect(
                                  "form-1.number"
                                ),
                                "data-vv-name": "number",
                                color: "secondary",
                                type: "number"
                              },
                              model: {
                                value: _vm.number,
                                callback: function($$v) {
                                  _vm.number = $$v
                                },
                                expression: "number"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-col", [_c("code", [_vm._v('type="number"')])])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { attrs: { align: "center", dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right body-1 grey--text",
                            attrs: { cols: "2" }
                          },
                          [_vm._v("\n              Url\n            ")]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|url",
                                  expression: "'required|url'"
                                }
                              ],
                              attrs: {
                                color: "secondary",
                                "data-vv-name": "url",
                                type: "url",
                                "error-messages": _vm.errors.collect(
                                  "form-1.url"
                                )
                              },
                              model: {
                                value: _vm.url,
                                callback: function($$v) {
                                  _vm.url = $$v
                                },
                                expression: "url"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-col", [_c("code", [_vm._v('type="url"')])])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "pa-3 text-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "success",
                              default: "",
                              type: "submit"
                            }
                          },
                          [
                            _vm._v(
                              "\n              Validate Inputs\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("v-col", { attrs: { cols: "12" } }, [
            _c(
              "form",
              {
                attrs: { action: "", "data-vv-scope": "form-2" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.validateForm("form-2")
                  }
                }
              },
              [
                _c(
                  "base-material-card",
                  {
                    attrs: {
                      color: "success",
                      inline: "",
                      title: "Range Validation"
                    }
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center", dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right body-1 grey--text",
                            attrs: { cols: "2" }
                          },
                          [_vm._v("\n              Min Length\n            ")]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|min:5",
                                  expression: "'required|min:5'"
                                }
                              ],
                              attrs: {
                                "error-messages": _vm.errors.collect(
                                  "form-2.min-length"
                                ),
                                color: "secondary",
                                "data-vv-name": "min-length"
                              },
                              model: {
                                value: _vm.min,
                                callback: function($$v) {
                                  _vm.min = $$v
                                },
                                expression: "min"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-col", [_c("code", [_vm._v('minLength="5"')])])
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { attrs: { align: "center", dense: "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right body-1 grey--text",
                            attrs: { cols: "2" }
                          },
                          [_vm._v("\n              Max Length\n            ")]
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "8" } },
                          [
                            _c("v-text-field", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|max:5",
                                  expression: "'required|max:5'"
                                }
                              ],
                              attrs: {
                                "error-messages": _vm.errors.collect(
                                  "form-2.max-length"
                                ),
                                color: "secondary",
                                "data-vv-name": "max-length"
                              },
                              model: {
                                value: _vm.max,
                                callback: function($$v) {
                                  _vm.max = $$v
                                },
                                expression: "max"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-col", [_c("code", [_vm._v('maxLength="5"')])])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "pa-3 text-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "success",
                              default: "",
                              type: "submit"
                            }
                          },
                          [
                            _vm._v(
                              "\n              Validate Inputs\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }